<template>
  <div
    class="wrap__content"
    v-bind:class="{ 'wrap__content--placeholder': !user || !application }"
  >
    <UiLoading v-if="!user || !application" />
    <template v-else>
      <div v-if="application.applicant" class="content__page--block">
        <notifications group="foo" position="bottom center" />

        <div class="content__page content__page--margintop">
          <div class="container">
            <UiBack :href="'/' + $i18n.locale + '/administration'" />

            <MainSectionTitle
              :title="$t('applic_for_subs_costs_tour')"
              :subtitle="`${$t('titles.application_for_tourist_childs')} №${
                application.id
              }`"
            />

            <div class="add_tourist--section add_tourist--section-transition">
              <div class="add_tourist--block">
                <div class="add_tourist__section--title">Данные Заявителя</div>
                <div class="table__block--style table__block--blue">
                  <v-data-table
                    :headers="headersApplicant"
                    :items="[data_applicant]"
                    :options.sync="options"
                    hide-default-footer
                  >
                    <template v-slot:item.reference="{ item }">
                      <div
                        v-for="(item, itemInd) in item.reference_src"
                        :key="item.id"
                      >
                        <a
                          class="tb__table--views"
                          :href="item.src.path"
                          data-fancybox="reference"
                          >{{ item.src.name }}</a
                        >
                      </div>
                    </template>
                    <template
                      v-slot:item.certificate_of_the_tour_operator="{ item }"
                    >
                      <a
                        class="tb__table--views"
                        :href="item.certificate_of_the_tour_operator_src"
                        data-fancybox="certificate_of_the_tour_operator"
                        >Посмотреть</a
                      >
                    </template>

                    <template v-slot:item.action="{ item }">
                      <a
                        :data-id="item.id"
                        :class="{
                          opacity: isThatStatus(
                            data_applicant.status,
                            'success',
                          ),
                        }"
                        v-tooltip.bottom="{
                          content: 'Одобрить',
                          class: ['table__tooltip'],
                        }"
                        class="tb__table--btn table__btn--status tb__table--success"
                        @click="changeStatus(data_applicant.status, 'success')"
                      >
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </a>
                      <a
                        :data-id="item.id"
                        :class="{
                          opacity: isThatStatus(data_applicant.status, 'fail'),
                        }"
                        v-tooltip.bottom="{
                          content: 'Отклонить',
                          class: ['table__tooltip'],
                        }"
                        class="tb__table--btn table__btn--status tb__table--close"
                        @click="changeStatus(data_applicant.status, 'fail')"
                      >
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </a>
                    </template>
                  </v-data-table>
                </div>
              </div>
              <div class="add_tourist--block">
                <div class="add_tourist__section--title">Данные пассажира</div>
                <div class="table__block--style table__block--blue">
                  <v-data-table
                    :headers="headers"
                    :items="userDataSave"
                    :options.sync="options"
                    hide-default-footer
                    :expanded.sync="expanded"
                    :single-expand="true"
                  >
                    <template v-slot:item="{ item, isExpanded, expand }">
                      <tr>
                        <td>{{ item.fullname }}</td>
                        <td>{{ item.document_number }}</td>
                        <td>{{ item.date_of_birth }}</td>
                        <td>{{ item.arrival_date }}</td>
                        <td>{{ item.date_of_departure }}</td>
                        <td>
                          <button
                            :class="{ 'docs__btn--active': isExpanded }"
                            class="docs__btn"
                            @click="expand(!isExpanded)"
                          >
                            <span>Посмотреть документы</span>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15 13.166l-5-5-5 5" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                          </button>
                        </td>
                        <td>
                          <a
                            class="tb__table--views"
                            :href="
                              item.information_on_the_provision_of_tourist_services_for_minor_passengers_src
                            "
                            data-fancybox="information_on_the_provision_of_tourist_services_for_minor_passengers"
                            >Посмотреть</a
                          >
                        </td>
                        <td>
                          <a
                            :data-id="item.id"
                            :class="{
                              opacity: isThatStatus(item.status, 'success'),
                            }"
                            v-tooltip.bottom="{
                              content: 'Одобрить',
                              class: ['table__tooltip'],
                            }"
                            class="tb__table--btn table__btn--status tb__table--success"
                            @click="isThatStatus(
                                  item.file_document_1.status,
                                  'success',
                                ) && isThatStatus(
                                  item.file_document_4.status,
                                  'success',
                                ) && isThatStatus(
                                  item.file_document_5.status,
                                  'success',
                                ) && isThatStatus(
                                  item.file_document_6.status,
                                  'success',
                                ) && changeStatus(item.status, 'success')"
                          >
                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                          </a>
                          <a
                            :data-id="item.id"
                            :class="{
                              opacity: isThatStatus(item.status, 'fail'),
                            }"
                            v-tooltip.bottom="{
                              content: 'Отклонить',
                              class: ['table__tooltip'],
                            }"
                            class="tb__table--btn table__btn--status tb__table--close"
                            @click="changeStatus(item.status, 'fail')"
                          >
                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                          </a>
                        </td>
                      </tr>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <div class="docs__list">
                          <div class="docs__list--title">
                            Подтверждающие документы
                          </div>
                          <div class="docs__list-docs">
                            <FileDocCheck
                              v-if="item.file_document_1"
                              :doc="item.file_document_1"
                              :label="$t('labels.file_document_1')"
                              :name="'file_document_1'"
                              :is-success="
                                isThatStatus(
                                  item.file_document_1.status,
                                  'success',
                                )
                              "
                              :is-fail="
                                isThatStatus(
                                  item.file_document_1.status,
                                  'fail',
                                )
                              "
                              @changeStatus="
                                changeStatus(
                                  item.file_document_1.status,
                                  $event,
                                ),
                                  $event === 'fail' &&
                                    changeStatus(item.status, 'fail')
                              "
                            />
                            <FileDocCheck
                              v-if="item.file_document_4"
                              :doc="item.file_document_4"
                              :add-docs="item.file_document_4_2"
                              :label="$t('labels.file_document_4')"
                              :name="'file_document_4'"
                              :is-success="
                                isThatStatus(
                                  item.file_document_4.status,
                                  'success',
                                )
                              "
                              :is-fail="
                                isThatStatus(
                                  item.file_document_4.status,
                                  'fail',
                                )
                              "
                              @changeStatus="
                                changeStatus(
                                  item.file_document_4.status,
                                  $event,
                                ),
                                  $event === 'fail' &&
                                    changeStatus(item.status, 'fail')
                              "
                            />
                            <FileDocCheck
                              v-if="item.file_document_5"
                              :doc="item.file_document_5"
                              :add-docs="item.file_document_5_2"
                              :label="$t('labels.file_document_5')"
                              :name="'file_document_1'"
                              :is-success="
                                isThatStatus(
                                  item.file_document_5.status,
                                  'success',
                                )
                              "
                              :is-fail="
                                isThatStatus(
                                  item.file_document_5.status,
                                  'fail',
                                )
                              "
                              @changeStatus="
                                changeStatus(
                                  item.file_document_5.status,
                                  $event,
                                ),
                                  $event === 'fail' &&
                                    changeStatus(item.status, 'fail')
                              "
                            />
                            <FileDocCheck
                              v-if="item.file_document_6"
                              :doc="item.file_document_6"
                              :add-docs="item.file_document_6_2"
                              :label="$t('labels.file_document_6')"
                              :name="'file_document_6'"
                              :is-success="
                                isThatStatus(
                                  item.file_document_6.status,
                                  'success',
                                )
                              "
                              :is-fail="
                                isThatStatus(
                                  item.file_document_6.status,
                                  'fail',
                                )
                              "
                              @changeStatus="
                                changeStatus(
                                  item.file_document_6.status,
                                  $event,
                                ),
                                  $event === 'fail' &&
                                    changeStatus(item.status, 'fail')
                              "
                            />
                          </div>
                        </div>
                      </td>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>

            <div class="add_tourist--section">
              <div class="add_tourist__section--title">
                Подтверждающие документы
              </div>

              <div class="add_tourist--filelist">
                <FileDocCheck
                  v-if="fileDocuments2"
                  :doc="fileDocuments2"
                  :label="$t('labels.file_document_2')"
                  :name="'fileDocuments2'"
                  :is-success="isThatStatus(fileDocuments2.status, 'success')"
                  :is-fail="isThatStatus(fileDocuments2.status, 'fail')"
                  @changeStatus="changeStatus(fileDocuments2.status, $event)"
                />
                <FileDocCheck
                  v-if="fileDocuments3"
                  :doc="fileDocuments3"
                  :label="$t('labels.file_document_3')"
                  :name="'fileDocuments3'"
                  :is-success="isThatStatus(fileDocuments3.status, 'success')"
                  :is-fail="isThatStatus(fileDocuments3.status, 'fail')"
                  @changeStatus="changeStatus(fileDocuments3.status, $event)"
                />

                <div class="fileitem__archive">
                  <a class="archive--link" @click="loadArchive(application.id)">
                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    Скачать все файлы архивом
                  </a>
                </div>
                <div
                  v-if="isHasRoles(['stationery'])"
                  class="form__block--line fileitem__comment"
                >
                  <div class="form__block--input">
                    <textarea
                      placeholder="Написать коментарий..."
                      :class="{ 'textarea--active': commentMessage }"
                      v-model="commentMessage"
                      class="input-linck textarea-linck input-linck--white"
                    ></textarea>
                  </div>
                </div>
              </div>

              <AppHistory :application="application" />

              <div class="modal__wrapper--footer">
                <div v-if="isHasRoles(['stationery'])" class="section--link">
                  <button
                    v-if="isHasStatuses(['sent-by', 'resending'])"
                    class="next__stage input__button"
                    :disabled="!(isAllSuccessed && !commentMessage)"
                    @click="onRegisterApplication"
                  >
                    Зарегистрировать заявление
                  </button>
                  <button
                    v-if="isHasStatuses(['sent-by', 'registered', 'resending'])"
                    class="next__stage input__button"
                    :disabled="!(commentMessage && isAllVoted)"
                    @click="onRetrieveApplication"
                  >
                    Вернуть заявителю с комментариями
                  </button>
                </div>

                <div class="section--link">
                  <button
                    v-if="
                      isHasStatuses(['sent-by']) && isHasRoles(['accountant'])
                    "
                    class="next__stage input__button"
                    @click="$modal.show('statement__success')"
                  >
                    Зарегистрировать заявление
                  </button>

                  <button
                    v-if="
                      isHasStatuses(['registered']) &&
                      isHasRoles(['accountant']) &&
                      isAllSuccessed
                    "
                    class="next__stage input__button"
                    @click="onConfirm"
                  >
                    Одобрить
                  </button>
                  <button
                    v-if="
                      isHasStatuses(['registered']) &&
                      isHasRoles(['accountant'])
                    "
                    class="next__stage input__button btn--error"
                    @click="$modal.show('statement__refuse')"
                  >
                    Отказать
                  </button>
                </div>

                <div class="section--link">
                  <button
                    v-if="
                      isHasStatuses(['approved']) &&
                      isHasRoles(['stationery', 'accountant'])
                    "
                    class="next__stage input__button"
                    @click="$modal.show('change_status')"
                  >
                    Изменить статус
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="user && application && !application.applicant"
        class="content__page--block"
      >
        <notifications group="foo" position="bottom center" />

        <div class="content__page content__page--margintop">
          <div class="container">
            <UiBack :href="'/' + $i18n.locale + '/administration'" />

            <div class="content__title--block">
              <div class="section__title--mini">
                Заявление на субсидирование затрат туроператоров в сфере
                въездного туризма
              </div>
              <div class="content__title section__title">
                <strong
                  >за несовершеннолетних пассажиров №{{
                    application.id
                  }}</strong
                >
              </div>
            </div>

            <div class="add_tourist--section add_tourist--section-transition">
              <div class="add_tourist--block">
                <div class="add_tourist__section--title">Данные</div>
                <div class="table__block--style table__block--blue">
                  <v-data-table
                    :headers="headersApplicant2"
                    :items="[data_applicant]"
                    :options.sync="options"
                    hide-default-footer
                  >
                    <template v-slot:item.receipt="{ item }">
                      <a
                        class="tb__table--views"
                        :href="item.receipt"
                        data-fancybox="receipt"
                        >Посмотреть</a
                      >
                    </template>

                    <template v-slot:item.action="{ item }">
                      <a
                        :data-id="item.id"
                        :class="{
                          opacity: isThatStatus(
                            data_applicant.status,
                            'success',
                          ),
                        }"
                        v-tooltip.bottom="{
                          content: 'Одобрить',
                          class: ['table__tooltip'],
                        }"
                        class="tb__table--btn table__btn--status tb__table--success"
                        @click="changeStatus(data_applicant.status, 'success')"
                      >
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </a>
                      <a
                        :data-id="item.id"
                        :class="{
                          opacity: isThatStatus(data_applicant.status, 'fail'),
                        }"
                        v-tooltip.bottom="{
                          content: 'Отклонить',
                          class: ['table__tooltip'],
                        }"
                        class="tb__table--btn table__btn--status tb__table--close"
                        @click="changeStatus(data_applicant.status, 'fail')"
                      >
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </a>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>

            <div
              v-if="isHasStatuses(['approved'])"
              class="add_tourist--section"
            >
              <div class="modal__wrapper--footer">
                <div v-if="isHasRoles(['stationery'])" class="section--link">
                  <button
                    v-if="isHasStatuses(['sent-by', 'resending'])"
                    :disabled="!(!commentMessage && isAllSuccessed)"
                    class="next__stage input__button"
                    @click="onRegisterApplication"
                  >
                    Зарегистрировать заявление
                  </button>
                  <button
                    v-if="isHasStatuses(['sent-by', 'registered', 'resending'])"
                    :disabled="!(commentMessage && isAllVoted)"
                    class="next__stage input__button"
                    @click="onRetrieveApplication"
                  >
                    Вернуть заявителю с комментариями
                  </button>
                </div>
                <div class="section--link">
                  <button
                    v-if="
                      isHasStatuses(['send-by']) && isHasRoles(['stationery'])
                    "
                    class="next__stage input__button"
                    @click="$modal.show('statement__success')"
                  >
                    Зарегистрировать заявление
                  </button>
                  <button
                    v-if="
                      isHasStatuses(['registered']) &&
                      isHasRoles(['accountant']) &&
                      isAllSuccessed
                    "
                    class="next__stage input__button"
                    @click="onConfirm"
                  >
                    Одобрить
                  </button>
                  <button
                    v-if="
                      isHasStatuses(['registered']) &&
                      isHasRoles(['accountant'])
                    "
                    class="next__stage input__button btn--error"
                    @click="$modal.show('statement__refuse')"
                  >
                    Отказать
                  </button>
                </div>

                <div class="section--link">
                  <button
                    v-if="
                      isHasStatuses(['approved']) &&
                      isHasRoles(['stationery', 'accountant'])
                    "
                    class="next__stage input__button"
                    @click="$modal.show('change_status')"
                  >
                    Изменить статус
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <StatRefuse />

    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="change_status"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div class="modal__block--close" @click="$modal.hide('change_status')">
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title"></div>
          <div class="modal__content">
            <form class="modal__form" method="post" @submit.prevent="onPaid">
              <input type="hidden" name="_method" value="PUT" />
              <input type="hidden" name="status[slug]" value="paid" />
              <div class="form__block--line">
                <div class="form__block--input input--error">
                  <label
                    class="form__file"
                    style="padding-left: 30px; padding-right: 30px"
                  >
                    <div class="form__file--title">
                      Загрузить квитанцию об оплате
                      <input type="file" name="receipt" style="display: none" />
                    </div>
                  </label>
                </div>
              </div>
              <div class="modal__wrapper--footer modal__wrapper--center">
                <button type="submit" class="next__stage input__button">
                  {{ $t('commands.save') }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </modal>

    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="statement__success"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div
          class="modal__block--close"
          @click="$modal.hide('statement__success')"
        >
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Зарегистрировать заявление</div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="modal__statement">
                <div class="modal__statement--text">
                  На субсидирование затрат туроператоров в сфере въездного
                  туризма<br />
                  <a
                    v-if="application"
                    href="#"
                    target="_blank"
                    class="modal__statement--link"
                    >за каждого иностранного туриста №{{ application.id }}</a
                  >
                </div>
              </div>

              <div class="modal__wrapper--footer modal__wrapper--center">
                <button
                  class="next__stage input__button"
                  @click="onRegisterApplication"
                >
                  Зарегистрировать заявление
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="statement__error"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div
          class="modal__block--close"
          @click="$modal.hide('statement__error')"
        >
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Проверьте все документы</div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="modal__statement">
                <div class="modal__statement--image">
                  <img src="../../../assets/img/statement__image.svg" />
                </div>
                <div class="modal__statement--text">
                  Для отправки комментариев, пожалуйста проверьте все документы
                </div>
              </div>

              <div class="modal__wrapper--footer modal__wrapper--center">
                <button class="next__stage input__button">Хорошо</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="ecp"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div class="modal__block--close" @click="$modal.hide('ecp')">
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title"></div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="form__block--line">
                <div class="form__block--input input--error">
                  <label
                    class="form__file"
                    style="padding-left: 30px; padding-right: 30px"
                  >
                    <div class="form__file--title">
                      {{ $t('auth.input.ecp') }}
                      <img src="../../../assets/img/file-icon.svg" />
                      <input
                        type="file"
                        name="ecp"
                        style="display: none"
                        accept=".p12"
                        v-on:change="handleEcp()"
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div v-if="ecp" class="form__block--line">
                <div class="form__block--input">
                  <div class="form__block--title">
                    {{ $t('auth.input.password') }} ЭЦП
                  </div>
                  <input
                    type="password"
                    class="input-linck"
                    required=""
                    name="password"
                    v-model="ecp_password"
                    :class="[message ? 'input--error' : '']"
                  />
                  <span class="input-required">{{ message }}</span>
                </div>
              </div>
              <div
                v-if="ecp_password"
                class="modal__wrapper--footer modal__wrapper--center"
              >
                <button class="next__stage input__button" @click="onConfirm">
                  Подписать ЭЦП ключом (RSA)
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { api } from '../../../boot/axios'

import fancyboxMixin from '../../../mixins/fancybox'

import { downloadZip } from '@/helpers/index'
import UiBack from '@/components/ui/UiBack.vue'
import UiLoading from '@/components/ui/UiLoading.vue'
import MainSectionTitle from '@/components/main/MainSectionTitle.vue'

import StatRefuse from './components/StatRefuse.vue'
import FileDocCheck from './components/FileDocCheck.vue'
import AppHistory from './components/AppHistory.vue'

export default {
  name: 'ViewsTourist',
  components: {
    UiBack,
    UiLoading,
    MainSectionTitle,
    StatRefuse,
    FileDocCheck,
    AppHistory,
  },
  mixins: [fancyboxMixin],
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      application: null,
      headersApplicant: [
        {
          text: 'Заявитель',
          value: 'name',
          width: '320px',
          sortable: false,
        },
        {
          text: 'БИН/ИИН',
          value: 'iin',
          sortable: false,
        },
        {
          text: 'Лицензия',
          value: 'reference',
          sortable: false,
        },
        {
          text: 'Заявление',
          value: 'certificate_of_the_tour_operator',
          sortable: false,
        },
        {
          text: 'Реквизиты',
          value: 'requisites',
          sortable: false,
        },
        {
          text: 'Действий',
          value: 'action',
          align: 'center',
        },
      ],
      headers: [
        {
          text: 'ФИО пассажира',
          value: 'fullname',
          sortable: false,
        },
        {
          text: '№ документа',
          value: 'document_number',
          sortable: false,
        },
        {
          text: 'Дата рождения',
          value: 'date_of_birth',
          sortable: false,
        },
        {
          text: 'Дата заезда',
          value: 'arrival_date',
          sortable: false,
        },
        {
          text: 'Дата выезда',
          value: 'date_of_departure',
          sortable: false,
        },
        {
          text: '',
          value: 'documents',
          sortable: false,
        },
        {
          text: 'Заявление',
          value:
            'information_on_the_provision_of_tourist_services_for_minor_passengers',
        },
        {
          text: 'Действий',
          value: 'action',
          align: 'center',
        },
      ],
      data_applicant: {},
      userDataSave: [],
      fileDocuments2: null,
      fileDocuments3: null,
      options: {
        itemsPerPage: 10,
      },
      expanded: [],
      commentMessage: null,
      headersApplicant2: [
        {
          text: 'Названия туроператора',
          value: 'name',
          sortable: false,
        },
        {
          text: 'БИН/ИИН',
          value: 'iin',
          sortable: false,
        },
        {
          text: 'ФИО пассажира',
          value: 'tourist_fullname',
          sortable: false,
        },
        {
          text: 'Сумма',
          value: 'payout_amount',
          sortable: false,
        },
        {
          text: 'Дата создания',
          value: 'created_date',
          sortable: false,
        },
        {
          text: this.$t('labels.status'),
          value: 'status',
          sortable: false,
        },
      ],

      tourist: {
        status: null,
      },
      ecp: null,
      ecp_password: null,
      message: null,
    }
  },
  computed: {
    isThatStatus() {
      return (obj, status) => {
        return obj[this.user?.role?.slug] === status
      }
    },
    getThatStatus() {
      return (obj) => {
        return obj[this.user.role.slug]
      }
    },
    isHasRoles() {
      return (roles) => {
        return roles?.includes(this.user?.role?.slug || '')
      }
    },
    isHasStatuses() {
      return (statuses) => {
        return statuses?.includes(this.application?.status?.slug || '')
      }
    },
    isAllVoted() {
      return (
        (this.isThatStatus(this.data_applicant.status, 'success') ||
          this.isThatStatus(this.data_applicant.status, 'fail')) &&
        this.userDataSave.every(
          (tourist) =>
            this.isThatStatus(tourist.status, 'success') ||
            this.isThatStatus(tourist.status, 'fail'),
        ) &&
        (this.isThatStatus(this.fileDocuments2.status, 'success') ||
          !this.fileDocuments2 ||
          this.isThatStatus(this.fileDocuments2.status, 'fail')) &&
        (this.isThatStatus(this.fileDocuments3.status, 'success') ||
          !this.fileDocuments3 ||
          this.isThatStatus(this.fileDocuments3.status, 'fail')) &&
        this.userDataSave.every(
          (tourist) =>
            (this.isThatStatus(tourist.file_document_1.status, 'success') ||
              !tourist.file_document_1 ||
              this.isThatStatus(tourist.file_document_1.status, 'fail')) &&
            (this.isThatStatus(tourist.file_document_4.status, 'success') ||
              !tourist.file_document_4 ||
              this.isThatStatus(tourist.file_document_4.status, 'fail')) &&
            (this.isThatStatus(tourist.file_document_5.status, 'success') ||
              !tourist.file_document_5 ||
              this.isThatStatus(tourist.file_document_5.status, 'fail')) &&
            (this.isThatStatus(tourist.file_document_6.status, 'success') ||
              !tourist.file_document_6 ||
              this.isThatStatus(tourist.file_document_6.status, 'fail')),
        )
      )
    },
    isAllSuccessed() {
      return (
        this.isThatStatus(this.data_applicant.status, 'success') &&
        this.userDataSave.every((tourist) =>
          this.isThatStatus(tourist.status, 'success'),
        ) &&
        this.isThatStatus(this.fileDocuments2.status, 'success') &&
        this.isThatStatus(this.fileDocuments3.status, 'success') &&
        this.userDataSave.every(
          (tourist) =>
            this.isThatStatus(tourist.file_document_1.status, 'success') &&
            this.isThatStatus(tourist.file_document_4.status, 'success') &&
            this.isThatStatus(tourist.file_document_5.status, 'success') &&
            this.isThatStatus(tourist.file_document_6.status, 'success'),
        )
      )
    },
  },
  beforeMount() {
    this.fetchApplication()
  },
  beforeCreate() {
    if (!localStorage.token)
      this.$router.push('/' + this.$i18n.locale + '/login')
  },
  methods: {
    fetchApplication() {
      api
        .get('applications/' + this.$route.params.application_id)
        .then((response) => {
          if (response.data) {
            this.application = response.data.data

            this.setDataApplicant()
            this.setUserDataSave()

            if (this.application) {
              this.fileDocuments2 = this.application.fields.file_document_2
              this.fileDocuments3 = this.application.fields.file_document_3
            }

            if (!this.application.applicant) {
              if (this.application.status.slug == 'paid') {
                this.headersApplicant2.push({
                  text: 'Квинтация об оплате',
                  value: 'receipt',
                  sortable: false,
                })
              }
            }
          }
        })
    },
    setDataApplicant() {
      this.data_applicant = {
        name:
          this.application.applicant.organization ||
          this.application.applicant.snp,
        iin: this.application.applicant.bin || this.application.applicant.iin,
        license: null,
        reference_src: this.application.applicant.certificate_of_debts?.map((cert) => ({
          ...cert,
          src: {
            ...cert.src,
            path: this.application.applicant.certificate_of_debt + cert.src.path
          }
        })) || null,
        certificate_of_the_tour_operator_src:
          this.application.certificate_of_the_tour_operator,
        requisites: this.application.applicant?.payment_accounts?.map(
          (payAcc) => payAcc.bik,
        ),
        status: this.application.applicant.status || {
          accountant: null,
          stationery: null,
        },
        created_date: this.application.created_at,
      }
    },
    setUserDataSave() {
      this.userDataSave =
        this.application?.fields.tourists?.map((tourist) => {
          return {
            id: tourist.id,
            fullname: tourist.fullname,
            document_number: tourist.document_number,
            date_of_birth: tourist.date_of_birth,
            arrival_date: tourist.arrival_date,
            date_of_departure: tourist.date_of_departure,
            departure_date: tourist.departure_date,
            information_on_the_provision_of_tourist_services_for_minor_passengers_src:
              this.application
                .information_on_the_provision_of_tourist_services_for_minor_passengers,
            file_document_1: {
              ...tourist.file_document_1,
            },
            file_document_4: {
              ...tourist.file_document_4,
            },
            file_document_4_2: {
              ...tourist.file_document_4_2,
            },
            file_document_5: {
              ...tourist.file_document_5,
            },
            file_document_5_2: {
              ...tourist.file_document_5_2,
            },
            file_document_6: {
              ...tourist.file_document_6,
            },
            file_document_6_2: {
              ...tourist.file_document_6_2,
            },
            status: tourist.status || {
              accountant: null,
              stationery: null,
            },
          }
        }) || []
    },
    changeStatus(obj, status) {
      obj[this.user.role.slug] = status
    },
    loadArchive(id) {
      api
        .get(
          `https://backend.subsidizing.crocos.kz/api/v1/applications/${id}/download-files`,
          {
            responseType: 'blob',
          },
        )
        .then((response) => {
          downloadZip(response.data)
        })
    },
    getAppCheckData() {
      const formData = new FormData()

      if (this.data_applicant.status)
        formData.append(
          'applicant[status]',
          this.getThatStatus(this.data_applicant.status),
        )

      this.userDataSave.forEach((tourist) => {
        formData.append(
          `tourist[${tourist.id}][status]`,
          this.getThatStatus(tourist.status),
        )
        formData.append(
          `tourist[${tourist.id}][file_document_1][status]`,
          this.getThatStatus(tourist.file_document_1.status),
        )
        formData.append(
          `tourist[${tourist.id}][file_document_4][status]`,
          this.getThatStatus(tourist.file_document_4.status),
        )
        formData.append(
          `tourist[${tourist.id}][file_document_5][status]`,
          this.getThatStatus(tourist.file_document_5.status),
        )
        formData.append(
          `tourist[${tourist.id}][file_document_6][status]`,
          this.getThatStatus(tourist.file_document_6.status),
        )
      })

      if (this.fileDocuments2.status)
        formData.append(
          'file_document_2[status]',
          this.getThatStatus(this.fileDocuments2.status),
        )
      if (this.fileDocuments3.status)
        formData.append(
          'file_document_3[status]',
          this.getThatStatus(this.fileDocuments3.status),
        )

      return formData
    },
    onRegisterApplication() {
      const formData = this.getAppCheckData()
      formData.append('_method', 'put')

      api
        .post(
          'applications/' + this.$route.params.application_id + '/registration',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then((response) => {
          if (response.data.code === 401) {
            this.$notify({
              group: 'foo',
              type: 'success',
              title: response.data.message,
              position: 'bottom center',
            })
            this.$toast(response.data.message)
            return
          }
          if (response.data.code == 200) {
            this.$notify({
              group: 'foo',
              type: 'success',
              title: 'Заявление успешно зарегистировано',
              position: 'bottom center',
            })

            this.application = response.data
          }
        })
    },
    onRetrieveApplication() {
      const formData = this.getAppCheckData()
      formData.append('_method', 'put')
      formData.append('message', this.commentMessage)

      api
        .post(
          'applications/' + this.$route.params.application_id + '/retrieve',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then((response) => {
          if (response.data.code == 200) {
            this.$notify({
              group: 'foo',
              title: 'Комментарии успешно отправлены',
              position: 'bottom center',
              type: 'success',
            })
            this.$router.push('/' + this.$i18n.locale + '/administration')
          }
        })
    },
    onConfirm() {
      const websocket = new WebSocket('wss://127.0.0.1:13579/')

      var ready = null

      let ecpData = {
        path: null,
        owner: null,
        data: null,
      }

      websocket.onopen = () => {
        ready = true

        handle()
      }

      websocket.onclose = (e) => {
        if (e.wasClean) {
          console.log('connection closed')
        } else {
          if (this.$i18n.locale == 'kz')
            alert('NCYLayer бағдарламасы іске қосылғандығына көз жеткізіңіз')
          if (this.$i18n.locale == 'ru')
            alert('Убедитесь, что программа NCALayer запущена')
          if (this.$i18n.locale == 'en')
            alert('Make sure that the NCALayer program is running')
          //setErrormodal(true);
          setTimeout(() => {
            //setErrormodal(false);
          }, 3000)
        }
      }

      function handle() {
        if (!ready) {
          alert('Убедитесь, что программа NCALayer запущена')
        } else {
          const data = {
            module: 'kz.gov.pki.knca.commonUtils',
            method: 'signXml',
            args: [
              'PKCS12',
              'SIGNATURE',
              '<login><sessionid>caacda70-fd36-45ed-8d94-45a88890f83a</sessionid></login>',
              '',
              '',
            ],
          }
          websocket.send(JSON.stringify(data))
        }
      }

      websocket.onmessage = (e) => {
        const data1 = JSON.parse(e.data)
        ecpData.data = data1.responseObject
        if (
          typeof ecpData.data === 'string' ||
          ecpData.data instanceof String
        ) {
          const formDataPki = new FormData()

          formDataPki.append('ecp', ecpData.data)

          api
            .post('pki-check', formDataPki, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              if (response.data.code == 401) {
                this.$notify({
                  group: 'foo',
                  type: 'error',
                  title: response.data.message,
                  position: 'bottom center',
                })
                return
              }
              if (response.data.code == 200) {
                const formData = this.getAppCheckData()
                formData.append('_method', 'put')
                formData.append('message', this.commentMessage)

                api
                  .post(
                    'applications/' +
                      this.$route.params.application_id +
                      '/confirm',
                    formData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    },
                  )
                  .then((response) => {
                    if (response.data.code == 200) {
                      this.$notify({
                        group: 'foo',
                        title: 'Заявление успешно одобрен',
                        position: 'bottom center',
                      })

                      this.$router.push(
                        '/' + this.$i18n.locale + '/administration',
                      )
                    }
                  })
              }
            })
        }
      }
    },
    onPaid(evt) {
      const formData = new FormData(evt.target)

      //	formData.append('_method', 'put');

      api
        .post(
          'applications/' + this.$route.params.application_id + '/total',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then((response) => {
          if (response.data.code == 200) {
            this.$notify({
              group: 'foo',
              title: 'Статус успешно изменен',
              position: 'bottom center',
            })

            this.$router.push('/' + this.$i18n.locale + '/administration')
          }
        })
    },
    handleEcp() {
      if (event.target.files[0].type == 'application/x-pkcs12') {
        this.ecp = event.target.files[0]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.opacity {
  opacity: 0.5;
}

.docs {
  &__btn {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.22;
    color: #6b69df;

    svg {
      transform-origin: center;
      transition: 0.3s linear;
      transform: rotateZ(180deg);
    }

    &--active {
      svg {
        transform: rotateZ(0deg);
      }
    }
  }

  &__list {
    width: 100%;
    box-sizing: border-box;
    padding: 30px 60px;
    border-radius: 22px;
    background: #f0f0fb;
  }

  &__list--title {
    text-align: center;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.22;
    color: #0e1839;
    text-transform: uppercase;
  }

  &__list-docs {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;

    & > div {
      width: 100%;
      max-width: unset;
    }
  }
}
</style>
